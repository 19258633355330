define('ember-cli-showdown/components/markdown-to-html', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var Handlebars = _ember['default'].Handlebars;

  var ShowdownComponent = _ember['default'].Component.extend({
    layout: _ember['default'].HTMLBars.template((function () {
      return {
        meta: {
          'revision': 'Ember@2.9.1',
          'loc': {
            'source': null,
            'start': {
              'line': 1,
              'column': 0
            },
            'end': {
              'line': 1,
              'column': 8
            }
          }
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment('');
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [['content', 'html', ['loc', [null, [1, 0], [1, 8]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })()),
    markdown: '',

    extensions: computed(function () {
      return [];
    }),

    defaultOptionKeys: computed(function () {
      return Object.keys(showdown.getDefaultOptions());
    }).readOnly(),

    html: computed('markdown', function () {
      var showdownOptions = this.getProperties(get(this, 'defaultOptionKeys'));

      for (var option in showdownOptions) {
        if (showdownOptions.hasOwnProperty(option)) {
          this.converter.setOption(option, showdownOptions[option]);
        }
      }

      return new Handlebars.SafeString(this.converter.makeHtml(get(this, 'markdown')));
    }).readOnly(),

    createConverter: function createConverter() {
      var extensions = get(this, 'extensions');

      if (typeof extensions === 'string') {
        extensions = extensions.split(' ');
      }

      return new showdown.Converter({ extensions: extensions });
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.converter = this.createConverter();
    }
  });

  ShowdownComponent.reopenClass({
    positionalParams: ['markdown']
  });

  exports['default'] = ShowdownComponent;
});
/* global showdown */