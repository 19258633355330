define('ember-composable-helpers/-private/closure-action', ['exports', 'ember'], function (exports, _ember) {

  var ClosureActionModule = undefined;

  if ('ember-htmlbars/keywords/closure-action' in _ember['default'].__loader.registry) {
    ClosureActionModule = _ember['default'].__loader.require('ember-htmlbars/keywords/closure-action');
  } else {
    ClosureActionModule = _ember['default'].__loader.require('ember-routing-htmlbars/keywords/closure-action');
  }

  exports['default'] = ClosureActionModule.ACTION;
});