define('ember-changeset/-private/internals', ['exports', 'ember'], function (exports, _ember) {
  exports.isChangeset = isChangeset;
  var get = _ember['default'].get;
  var CHANGESET = '__CHANGESET__';

  exports.CHANGESET = CHANGESET;

  function isChangeset(changeset) {
    return get(changeset, '__changeset__') === CHANGESET;
  }
});